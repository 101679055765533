import { useMemo } from 'react';
import { Box, Button, VStack, Textarea, Input } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { GroupController, Select, Radio, Checkbox } from 'frontend-components';
import { useStepId, useStore, useSubmitCustomStepForm } from 'frontend-common';

const keyrock_relationship =
  'How have you been entering in a relationship with Keyrock?';
const keyrock_relationship_options = [
  "I have been contacted by Keyrock's team",
  'I solicited Keyrock',
  'I have been introduced to Keyrock via a third party',
  'Other',
];
const keyrock_relationship_other = 'keyrock_relationship_other';
const third_party_introducer =
  'If you have been introduced to Keyrock via a third party, please provide its identification data';
const keyrock_contact_person =
  'Please provide the name of your contact person at Keyrock';
const purpose_of_your_business_relationship =
  'Please provide the purpose of your business relationship with Keyrock';
const purpose_of_your_business_relationship_options = [
  'Market Making',
  'OTC/OEX',
];

//questions triggered if 'purpose_of_your_business_relationship' = 'Market Making'
const market_making_asset =
  'Which pairs will you require Market Making services for';
const market_making_asset_options = ['FIAT/Crypto', 'Crypto/Crypto'];
const market_making_all_currencies =
  'Please indicate all pairs in scope of Market Making services';
const is_token_issuer = 'Are you the issuer of this token';
const token_classification = 'How is the token classified';
const token_classification_options = [
  'Payment token',
  'Utility token',
  'Security token',
  'Other',
];
const token_classification_other = 'token_classification_other';
const whitepaper_available = 'Do you have a Whitepaper available for the token';
const token_status_legal_opinion =
  'Do you have a legal opinion/letter on the status of the token';
const no_token_status_legal_opinion_explained =
  'Kindly clarify why the opinion is not available, and whether we can expect it to be available in the future';

const market_making_volume_of_assets =
  'What is the volume of assets you’ll require Keyrock to provide Market Making on';
const market_making_volume_of_assets_options = [
  '< 500K Eur',
  'Between 500K Eur and 3M Eur',
  '> 3M Eur',
  'I won’t send assets to Keyrock',
];

//questions triggered if 'purpose_of_your_business_relationship' = 'OTC/OEX'
const otc_trading_types =
  'What type of OTC trading do you intend to do with Keyrock';
const otc_trading_types_options = ['OTC Spot Trading', 'OTC Options Trading'];
const otc_currencies = 'What currencies would you like to trade with Keyrock';
const otc_currencies_options = ['Crypto/FIAT', 'Crypto/Crypto'];
const otc_fiat_currencies_explained =
  'Please indicate all fiat currencies that will be involved';
const otc_cryptocurrencies_explained =
  'Please indicate all cryptocurrencies that will be involved';
const otc_volume_of_transaction =
  'What is the expected value and volume of the transaction';
const otc_volume_of_transaction_options = [
  '< 500K Eur',
  'Between 500K Eur and 3M Eur',
  '> 3M Eur',
  'I won’t send assets to Keyrock',
];
const otc_timeframe_of_transactions =
  'What is the expected timeframe of the expected transaction(s)';
const otc_timeframe_of_transactions_options = [
  'Daily',
  'Weekly',
  'Monthly',
  'Quarterly',
  'Other',
];
const otc_timeframe_of_transactions_other = 'timeframe_of_transactions_other';

//questions for both options
const source_of_funds = 'What is the source of your funds?';

const provided_bank_details = 'Please provide your bank details';

const validationSchema = Yup.object({
  [keyrock_relationship]: Yup.string()
    .oneOf(keyrock_relationship_options)
    .label('This field')
    .required(),
  [keyrock_relationship_other]: Yup.string().when(keyrock_relationship, {
    is: (val: string) => val === 'Other',
    then: (schema) => schema.label('This field').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [third_party_introducer]: Yup.string().when(keyrock_relationship, {
    is: (val: string) =>
      val === 'I have been introduced to Keyrock via a third party',
    then: (schema) => schema.label('This field').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [keyrock_contact_person]: Yup.string().required().label('This'),
  [purpose_of_your_business_relationship]: Yup.string()
    .oneOf(purpose_of_your_business_relationship_options)
    .required()
    .label('This'),
  [market_making_asset]: Yup.array().when(
    purpose_of_your_business_relationship,
    {
      is: (val: string) => val === 'Market Making',
      then: (schema) => schema.min(1).label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [market_making_all_currencies]: Yup.string().when(
    purpose_of_your_business_relationship,
    {
      is: (val: string) => val === 'Market Making',
      then: (schema) => schema.required().label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [is_token_issuer]: Yup.string().when(purpose_of_your_business_relationship, {
    is: (val: string) => val === 'Market Making',
    then: (schema) => schema.oneOf(['Yes', 'No']).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [token_classification]: Yup.string().when(is_token_issuer, {
    is: (val: string) => val === 'Yes',
    then: (schema) =>
      schema.oneOf(token_classification_options).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [token_classification_other]: Yup.string().when(token_classification, {
    is: (val: string) => val === 'Other',
    then: (schema) => schema.label('This field').required(),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [whitepaper_available]: Yup.string().when(is_token_issuer, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.oneOf(['Yes', 'No']).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [token_status_legal_opinion]: Yup.string().when(is_token_issuer, {
    is: (val: string) => val === 'Yes',
    then: (schema) => schema.oneOf(['Yes', 'No']).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [no_token_status_legal_opinion_explained]: Yup.string().when(
    token_status_legal_opinion,
    {
      is: (val: string) => val === 'No',
      then: (schema) => schema.required().label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [market_making_volume_of_assets]: Yup.string().when(
    purpose_of_your_business_relationship,
    {
      is: (val: string) => val === 'Market Making',
      then: (schema) =>
        schema
          .oneOf(market_making_volume_of_assets_options)
          .required()
          .label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [otc_trading_types]: Yup.array().when(purpose_of_your_business_relationship, {
    is: (val: string) => val === 'OTC/OEX',
    then: (schema) => schema.min(1).label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [otc_currencies]: Yup.string().when(purpose_of_your_business_relationship, {
    is: (val: string) => val === 'OTC/OEX',
    then: (schema) =>
      schema.oneOf(otc_currencies_options).required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [otc_fiat_currencies_explained]: Yup.string().when(otc_currencies, {
    is: (val: string) => val === 'Crypto/FIAT',
    then: (schema) => schema.required().label('This'),
    otherwise: (schema) =>
      schema
        .optional()
        .nullable()
        .default(null)
        .transform(() => null),
  }),
  [otc_cryptocurrencies_explained]: Yup.string().when(
    purpose_of_your_business_relationship,
    {
      is: (val: string) => val === 'OTC/OEX',
      then: (schema) => schema.label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [otc_volume_of_transaction]: Yup.string().when(
    purpose_of_your_business_relationship,
    {
      is: (val: string) => val === 'OTC/OEX',
      then: (schema) =>
        schema
          .oneOf(otc_volume_of_transaction_options)
          .required()
          .label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [otc_timeframe_of_transactions]: Yup.string().when(
    purpose_of_your_business_relationship,
    {
      is: (val: string) => val === 'OTC/OEX',
      then: (schema) =>
        schema
          .oneOf(otc_timeframe_of_transactions_options)
          .required()
          .label('This'),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [otc_timeframe_of_transactions_other]: Yup.string().when(
    otc_timeframe_of_transactions,
    {
      is: (val: string) => val === 'Other',
      then: (schema) => schema.label('This field').required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
  [source_of_funds]: Yup.string().label('This field').required(),
  [provided_bank_details]: Yup.string().when(
    [otc_currencies, market_making_asset],
    {
      is: (otc_currencies: string, market_making_asset: string[]) =>
        otc_currencies === 'Crypto/FIAT' ||
        (market_making_asset ?? []).includes('FIAT/Crypto'),
      then: (schema) => schema.label('This field').required(),
      otherwise: (schema) =>
        schema
          .optional()
          .nullable()
          .default(null)
          .transform(() => null),
    },
  ),
});

export const BusinessRelationship = () => {
  const stepId = useStepId();
  const { t } = useTranslation();
  const { submitCustomStepForm } = useSubmitCustomStepForm();
  const { metadata } = useStore();

  const defaultValues = useMemo(() => {
    return {
      [keyrock_relationship]: metadata?.[keyrock_relationship] || '',
      [keyrock_relationship_other]:
        metadata?.[keyrock_relationship_other] || '',
      [third_party_introducer]: metadata?.[third_party_introducer] || '',
      [keyrock_contact_person]: metadata?.[keyrock_contact_person] || '',
      [purpose_of_your_business_relationship]:
        metadata?.[purpose_of_your_business_relationship] || '',
      [market_making_asset]: metadata?.[market_making_asset] || '',
      [market_making_all_currencies]:
        metadata?.[market_making_all_currencies] || '',
      [is_token_issuer]: metadata?.[is_token_issuer] || '',
      [token_classification]: metadata?.[token_classification] || '',
      [token_classification_other]:
        metadata?.[token_classification_other] || '',
      [whitepaper_available]: metadata?.[whitepaper_available] || '',
      [token_status_legal_opinion]:
        metadata?.[token_status_legal_opinion] || '',
      [no_token_status_legal_opinion_explained]:
        metadata?.[no_token_status_legal_opinion_explained] || '',
      [market_making_volume_of_assets]:
        metadata?.[market_making_volume_of_assets] || '',
      [otc_trading_types]: metadata?.[otc_trading_types] || '',
      [otc_currencies]: metadata?.[otc_currencies] || '',
      [otc_fiat_currencies_explained]:
        metadata?.[otc_fiat_currencies_explained] || '',
      [otc_cryptocurrencies_explained]:
        metadata?.[otc_cryptocurrencies_explained] || '',
      [otc_volume_of_transaction]: metadata?.[otc_volume_of_transaction] || '',
      [otc_timeframe_of_transactions]:
        metadata?.[otc_timeframe_of_transactions] || '',
      [otc_timeframe_of_transactions_other]:
        metadata?.[otc_timeframe_of_transactions_other] || '',
      [provided_bank_details]: metadata?.[provided_bank_details] || '',
      [source_of_funds]: metadata?.[source_of_funds] || '',
    };
  }, [metadata]);

  const methods = useForm<any>({
    mode: 'all',
    criteriaMode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    resetField,
    formState: { isValid, isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<any> = async (formData) => {
    submitCustomStepForm({ caseMetadata: formData });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="6" alignItems="start">
        <GroupController
          name={keyrock_relationship}
          label={t(`steps.${stepId}.${keyrock_relationship}.label`)}
          isRequired={true}
          control={control}
          render={(f) => (
            <Select
              stepId={stepId}
              name={keyrock_relationship}
              onChange={(value: string) => {
                resetField(keyrock_relationship_other);
                resetField(third_party_introducer);
                setValue(keyrock_relationship, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={keyrock_relationship_options}
              defaultValue={f.value}
              hasOtherOption={false}
            />
          )}
        />

        {watch(keyrock_relationship) === 'Other' && (
          <GroupController
            name={keyrock_relationship_other}
            label={t(`steps.${stepId}.${keyrock_relationship_other}.label`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Input type="text" maxW="400px" {...f} />;
            }}
          />
        )}

        {watch(keyrock_relationship) ===
          'I have been introduced to Keyrock via a third party' && (
          <GroupController
            name={third_party_introducer}
            label={t(`steps.${stepId}.${third_party_introducer}.label`)}
            helper={t(`steps.${stepId}.${third_party_introducer}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Textarea maxW="400px" resize="vertical" {...f} />;
            }}
          />
        )}

        <GroupController
          name={keyrock_contact_person}
          label={t(`steps.${stepId}.${keyrock_contact_person}.label`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Input type="text" maxW="400px" {...f} />;
          }}
        />

        <GroupController
          name={purpose_of_your_business_relationship}
          label={t(
            `steps.${stepId}.${purpose_of_your_business_relationship}.label`,
          )}
          isRequired={true}
          control={control}
          render={(f) => (
            <Radio
              stepId={stepId}
              name={purpose_of_your_business_relationship}
              onChange={(value: string) => {
                resetField(market_making_asset);
                resetField(market_making_all_currencies);
                resetField(is_token_issuer);
                resetField(token_classification);
                resetField(token_classification_other);
                resetField(whitepaper_available);
                resetField(token_status_legal_opinion);
                resetField(no_token_status_legal_opinion_explained);
                resetField(market_making_volume_of_assets);
                resetField(otc_trading_types);
                resetField(otc_currencies);
                resetField(otc_fiat_currencies_explained);
                resetField(otc_cryptocurrencies_explained);
                resetField(otc_volume_of_transaction);
                resetField(otc_timeframe_of_transactions);
                resetField(otc_timeframe_of_transactions_other);
                setValue(purpose_of_your_business_relationship, value ?? '', {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
              options={purpose_of_your_business_relationship_options}
              defaultValue={f.value}
            />
          )}
        />

        {watch(purpose_of_your_business_relationship) === 'Market Making' && (
          <>
            <GroupController
              name={market_making_asset}
              label={t(`steps.${stepId}.${market_making_asset}.label`)}
              isRequired={false}
              control={control}
              render={(f) => (
                <Checkbox
                  stepId={stepId}
                  name={market_making_asset}
                  onChange={(value: string[]) => {
                    resetField(provided_bank_details);
                    setValue(market_making_asset, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={market_making_asset_options}
                  defaultValue={f.value}
                />
              )}
            />

            <GroupController
              name={market_making_all_currencies}
              label={t(`steps.${stepId}.${market_making_all_currencies}.label`)}
              isRequired={true}
              control={control}
              render={(f) => {
                return (
                  <Textarea
                    maxW="400px"
                    placeholder="SOL/ETH, SOL/BTC, SOL/EUR … "
                    resize="vertical"
                    {...f}
                  />
                );
              }}
            />

            <GroupController
              name={is_token_issuer}
              label={t(`steps.${stepId}.${is_token_issuer}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={is_token_issuer}
                  onChange={(value: string) => {
                    resetField(token_classification);
                    resetField(token_classification_other);
                    resetField(whitepaper_available);
                    resetField(token_status_legal_opinion);
                    resetField(no_token_status_legal_opinion_explained);
                    setValue(is_token_issuer, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={['Yes', 'No']}
                  defaultValue={f.value}
                />
              )}
            />
            {watch(is_token_issuer) === 'Yes' && (
              <>
                <GroupController
                  name={token_classification}
                  label={t(`steps.${stepId}.${token_classification}.label`)}
                  isRequired={true}
                  control={control}
                  render={(f) => (
                    <Select
                      stepId={stepId}
                      name={token_classification}
                      onChange={(value: string) => {
                        resetField(token_classification_other);
                        setValue(token_classification, value ?? '', {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      options={token_classification_options}
                      defaultValue={f.value}
                      hasOtherOption={false}
                    />
                  )}
                />

                {watch(token_classification) === 'Other' && (
                  <GroupController
                    name={token_classification_other}
                    label={t(
                      `steps.${stepId}.${token_classification_other}.label`,
                    )}
                    isRequired={true}
                    control={control}
                    render={(f) => {
                      return <Input type="text" maxW="400px" {...f} />;
                    }}
                  />
                )}

                <GroupController
                  name={whitepaper_available}
                  label={t(`steps.${stepId}.${whitepaper_available}.label`)}
                  isRequired={true}
                  control={control}
                  render={(f) => (
                    <Radio
                      stepId={stepId}
                      name={whitepaper_available}
                      onChange={(value: string) => {
                        setValue(whitepaper_available, value ?? '', {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      options={['Yes', 'No']}
                      defaultValue={f.value}
                    />
                  )}
                />

                <GroupController
                  name={token_status_legal_opinion}
                  label={t(
                    `steps.${stepId}.${token_status_legal_opinion}.label`,
                  )}
                  isRequired={true}
                  control={control}
                  render={(f) => (
                    <Radio
                      stepId={stepId}
                      name={token_status_legal_opinion}
                      onChange={(value: string) => {
                        resetField(no_token_status_legal_opinion_explained);
                        setValue(token_status_legal_opinion, value ?? '', {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      }}
                      options={['Yes', 'No']}
                      defaultValue={f.value}
                    />
                  )}
                />

                {watch(token_status_legal_opinion) === 'No' && (
                  <GroupController
                    name={no_token_status_legal_opinion_explained}
                    label={t(
                      `steps.${stepId}.${no_token_status_legal_opinion_explained}.label`,
                    )}
                    isRequired={true}
                    control={control}
                    render={(f) => {
                      return <Input type="text" maxW="400px" {...f} />;
                    }}
                  />
                )}
              </>
            )}

            <GroupController
              name={market_making_volume_of_assets}
              label={t(
                `steps.${stepId}.${market_making_volume_of_assets}.label`,
              )}
              isRequired={true}
              control={control}
              render={(f) => (
                <Select
                  stepId={stepId}
                  name={market_making_volume_of_assets}
                  onChange={(value: string) => {
                    setValue(market_making_volume_of_assets, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={market_making_volume_of_assets_options}
                  defaultValue={f.value}
                  hasOtherOption={false}
                />
              )}
            />
          </>
        )}

        {watch(purpose_of_your_business_relationship) === 'OTC/OEX' && (
          <>
            <GroupController
              name={otc_trading_types}
              label={t(`steps.${stepId}.${otc_trading_types}.label`)}
              isRequired={false}
              control={control}
              render={(f) => (
                <Checkbox
                  stepId={stepId}
                  name={otc_trading_types}
                  onChange={(value: string[]) => {
                    setValue(otc_trading_types, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={otc_trading_types_options}
                  defaultValue={f.value}
                />
              )}
            />

            <GroupController
              name={otc_currencies}
              label={t(`steps.${stepId}.${otc_currencies}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Radio
                  stepId={stepId}
                  name={otc_currencies}
                  onChange={(value: string) => {
                    resetField(otc_fiat_currencies_explained);
                    resetField(otc_cryptocurrencies_explained);
                    resetField(provided_bank_details);
                    setValue(otc_currencies, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={otc_currencies_options}
                  defaultValue={f.value}
                />
              )}
            />

            {watch(otc_currencies) === 'Crypto/FIAT' && (
              <GroupController
                name={otc_fiat_currencies_explained}
                label={t(
                  `steps.${stepId}.${otc_fiat_currencies_explained}.label`,
                )}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}

            <GroupController
              name={otc_cryptocurrencies_explained}
              label={t(
                `steps.${stepId}.${otc_cryptocurrencies_explained}.label`,
              )}
              isRequired={true}
              control={control}
              render={(f) => {
                return <Input type="text" maxW="400px" {...f} />;
              }}
            />

            <GroupController
              name={otc_volume_of_transaction}
              label={t(`steps.${stepId}.${otc_volume_of_transaction}.label`)}
              isRequired={true}
              control={control}
              render={(f) => (
                <Select
                  stepId={stepId}
                  name={otc_volume_of_transaction}
                  onChange={(value: string) => {
                    setValue(otc_volume_of_transaction, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={otc_volume_of_transaction_options}
                  defaultValue={f.value}
                  hasOtherOption={false}
                />
              )}
            />

            <GroupController
              name={otc_timeframe_of_transactions}
              label={t(
                `steps.${stepId}.${otc_timeframe_of_transactions}.label`,
              )}
              isRequired={true}
              control={control}
              render={(f) => (
                <Select
                  stepId={stepId}
                  name={otc_timeframe_of_transactions}
                  onChange={(value: string) => {
                    resetField(otc_timeframe_of_transactions_other);
                    setValue(otc_timeframe_of_transactions, value ?? '', {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                  options={otc_timeframe_of_transactions_options}
                  defaultValue={f.value}
                  hasOtherOption={false}
                />
              )}
            />

            {watch(otc_timeframe_of_transactions) === 'Other' && (
              <GroupController
                name={otc_timeframe_of_transactions_other}
                label={t(
                  `steps.${stepId}.${otc_timeframe_of_transactions_other}.label`,
                )}
                isRequired={true}
                control={control}
                render={(f) => {
                  return <Input type="text" maxW="400px" {...f} />;
                }}
              />
            )}
          </>
        )}

        {(watch(otc_currencies) === 'Crypto/FIAT' ||
          watch(market_making_asset).includes('FIAT/Crypto')) && (
          <GroupController
            name={provided_bank_details}
            label={t(`steps.${stepId}.${provided_bank_details}.label`)}
            helper={t(`steps.${stepId}.${provided_bank_details}.helper`)}
            isRequired={true}
            control={control}
            render={(f) => {
              return <Textarea maxW="400px" resize="vertical" {...f} />;
            }}
          />
        )}

        <GroupController
          name={source_of_funds}
          label={t(`steps.${stepId}.${source_of_funds}.label`)}
          helper={t(`steps.${stepId}.${source_of_funds}.helper`)}
          isRequired={true}
          control={control}
          render={(f) => {
            return <Textarea maxW="400px" resize="vertical" {...f} />;
          }}
        />

        <Box>
          <Button
            variant="next"
            isLoading={isSubmitting}
            isDisabled={!isValid}
            type="submit"
          >
            {t('domain.form.next')}
          </Button>
        </Box>
      </VStack>
    </form>
  );
};
